import { FC, PropsWithChildren } from 'react'
import Header from '../Header/Header'
import { Footer } from '@/root/modules/bluewave/AppLayout/components/Footer/Footer'
import { useErrorBanner } from '@/root/shared-hooks/useErrorBanner/useErrorBanner'
import { Banner } from '@/root/ui/bluewave'
import { globalFont } from '../../assets/globalFonts/globalFonts'
import { DayPickerProvider } from 'react-day-picker'

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const { errorBody } = useErrorBanner()
  return (
    <DayPickerProvider initialProps={{ mode: 'single' }}>
      <div className={`${globalFont.className} flex h-screen min-w-400 flex-col`}>
        <Header />
        <main className="bg-neutral-ultra-lightest grow">
          {errorBody && (
            <Banner title="Error" variant="error" className="mx-auto mt-10 max-w-7xl">
              <p className="font-bold">{errorBody}</p>
            </Banner>
          )}
          {children}
        </main>
        <Footer />
      </div>
    </DayPickerProvider>
  )
}

export default AppLayout
