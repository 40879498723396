import React, { FC } from 'react'
import { Direction, SearchBarDate } from '@/root/shared-types'
import OutsideClickHandler from 'react-outside-click-handler'
import { timeFormat } from '@/root/utils/timeFormat'
import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import DayPicker from '@/root/components/shared/DayPicker/DayPicker'
import { DatePlaceholder } from '../DatePlaceholder/DatePlaceholder'
import { checkIsDayDisabled } from '@/root/shared-helpers'
import { DayPickerTitle } from '../DayPickerTitle/DayPickerTitle'
import { isHTMLElement } from '@floating-ui/utils/dom'
import cn from 'classnames'
import { ChevronUp } from '../ChevronUp/ChevronUp'

interface SearchbarDayPickersProps {
  selectedDate: SearchBarDate
  onDateChange: (direction: Direction) => (date: Date) => void
  availableDates: { availableDatesTo: Array<Date>; availableDatesFrom: Array<Date> }
  titleTo: string
  isOpened: boolean
  toggleDayPickers(): void
  titleFrom?: string
  isRoundTrip?: boolean
}

export const SearchbarDayPickers: FC<SearchbarDayPickersProps> = ({
  selectedDate,
  onDateChange,
  availableDates,
  titleTo,
  titleFrom,
  isRoundTrip = false,
  isOpened,
  toggleDayPickers,
}) => {
  const { getTranslation } = useGetTranslations()
  const { locale } = useCustomRouter()

  const selectedDateTo = selectedDate.to && timeFormat(selectedDate.to, locale)
  const selectedDateFrom = selectedDate.from && timeFormat(selectedDate.from, locale)

  return (
    <span className="relative">
      <span className="text-primary-base flex gap-1 ps-5" onClick={toggleDayPickers}>
        <DatePlaceholder
          date={selectedDateTo}
          placeholder={getTranslation('searchBarWhenPlaceholder')}
          truncate={isRoundTrip}
        />
        {isRoundTrip && (
          <>
            <DatePlaceholder placeholder={''} displaySeparator />
            <DatePlaceholder
              placeholder={getTranslation('searchBarWhenPlaceholder')}
              date={selectedDateFrom}
              truncate
            />
          </>
        )}
      </span>
      {isOpened && (
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (!(isHTMLElement(e.target) && e.target.closest('label')?.querySelector('input[type="checkbox"]'))) {
              toggleDayPickers()
            }
          }}
        >
          <span className="absolute -top-48 start-1/2 z-10 flex flex-col items-center overflow-hidden bg-white shadow-lg lg:top-5 lg:mt-4 lg:flex-row lg:items-end ltr:-translate-x-1/2 rtl:translate-x-1/2">
            <DayPicker
              className={cn('p-2 xxs:w-[335px] xxs:p-4', {
                'xxs:pb-2 lg:w/[372px] lg:pb-4 lg:pe-2': isRoundTrip,
              })}
              isDayDisabled={checkIsDayDisabled({
                availableDates: availableDates.availableDatesTo,
              })}
              onDayClick={onDateChange('to')}
              selectedDate={selectedDate.to}
            >
              {titleTo && <DayPickerTitle title={titleTo} />}
            </DayPicker>
            {isRoundTrip && (
              <>
                <ChevronUp onClick={toggleDayPickers} />
                <DayPicker
                  className="w-screen p-2 xxs:w-[380px] xxs:p-4 xxs:pt-2 lg:w-[372px] lg:ps-2 lg:pt-4"
                  isDayDisabled={checkIsDayDisabled({
                    availableDates: availableDates.availableDatesFrom,
                    disableBeforeDate: selectedDate.to,
                  })}
                  onDayClick={onDateChange('from')}
                  selectedDate={selectedDate.from || selectedDate.to}
                >
                  {titleFrom && <DayPickerTitle title={titleFrom} />}
                </DayPicker>
              </>
            )}
          </span>
        </OutsideClickHandler>
      )}
    </span>
  )
}
